var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "c-tree-table",
              {
                attrs: {
                  title: "자재분류 목록",
                  parentProperty: "upMaterialTypeCd",
                  customID: "materialTypeCd",
                  columns: _vm.gridType.columns,
                  data: _vm.gridType.data,
                  columnSetting: false,
                  isFullScreen: false,
                  usePaging: false,
                  expandAll: true,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "LBLSEARCH", icon: "search" },
                          on: { btnClicked: _vm.getList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "자재분류 상세정보" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "LBLREG", icon: "add" },
                                  on: { btnClicked: _vm.addType },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.dataeditable
                              ? _c("c-btn", {
                                  attrs: { label: "LBLREMOVE", icon: "remove" },
                                  on: { btnClicked: _vm.delType },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.saveable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.materialTypeData,
                                    mappingType: _vm.saveType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveTypes,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable && _vm.dataeditable,
                              label: "자재분류 코드",
                              name: "materialTypeCd",
                            },
                            model: {
                              value: _vm.materialTypeData.materialTypeCd,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.materialTypeData,
                                  "materialTypeCd",
                                  $$v
                                )
                              },
                              expression: "materialTypeData.materialTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable && _vm.dataeditable,
                              label: "자재분류 명칭",
                              name: "materialTypeName",
                            },
                            model: {
                              value: _vm.materialTypeData.materialTypeName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.materialTypeData,
                                  "materialTypeName",
                                  $$v
                                )
                              },
                              expression: "materialTypeData.materialTypeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              afterIcon: _vm.editable
                                ? [
                                    {
                                      name: "search",
                                      click: true,
                                      callbackName: "selectUpType",
                                      color: "light-blue",
                                    },
                                    {
                                      name: "close",
                                      click: true,
                                      callbackName: "removeUpType",
                                      color: "red",
                                    },
                                  ]
                                : null,
                              disabled: true,
                              editable: _vm.editable && _vm.dataeditable,
                              label: "상위 자재분류",
                              name: "upMaterialTypeName",
                            },
                            on: {
                              selectUpType: () => {
                                _vm.isUpTypeOpen = true
                              },
                              removeUpType: _vm.removeUpType,
                            },
                            model: {
                              value: _vm.materialTypeData.upMaterialTypeName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.materialTypeData,
                                  "upMaterialTypeName",
                                  $$v
                                )
                              },
                              expression: "materialTypeData.upMaterialTypeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable && _vm.dataeditable,
                              label: "순번",
                              name: "sortOrder",
                              type: "number",
                            },
                            model: {
                              value: _vm.materialTypeData.sortOrder,
                              callback: function ($$v) {
                                _vm.$set(_vm.materialTypeData, "sortOrder", $$v)
                              },
                              expression: "materialTypeData.sortOrder",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-radio", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              codeGroupCd: "MATERIAL_KIND_CD",
                              label: "자재구분",
                              name: "materialKindCd",
                            },
                            model: {
                              value: _vm.materialTypeData.materialKindCd,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.materialTypeData,
                                  "materialKindCd",
                                  $$v
                                )
                              },
                              expression: "materialTypeData.materialKindCd",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "q-dialog",
        {
          ref: "upTypeDialog",
          attrs: { persistent: "" },
          model: {
            value: _vm.isUpTypeOpen,
            callback: function ($$v) {
              _vm.isUpTypeOpen = $$v
            },
            expression: "isUpTypeOpen",
          },
        },
        [
          _c(
            "q-card",
            { staticClass: "menu-card", staticStyle: { width: "600px" } },
            [
              _c(
                "q-card-section",
                {
                  staticClass:
                    "row items-center q-pb-none px-2 py-1 bg-orange-custom text-white",
                },
                [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v("상위 자재분류"),
                  ]),
                  _c("q-space"),
                  _c("q-btn", {
                    attrs: { icon: "done", flat: "", round: "", dense: "" },
                    on: { click: _vm.selectUpType },
                  }),
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { icon: "close", flat: "", round: "", dense: "" },
                  }),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c(
                        "q-item-label",
                        { attrs: { caption: "" } },
                        [
                          _c(
                            "q-chip",
                            {
                              attrs: {
                                outline: "",
                                square: "",
                                color: "primary",
                                "text-color": "white",
                              },
                            },
                            [
                              _c("q-icon", { attrs: { name: "check" } }),
                              _vm._v(_vm._s(_vm.upTypeNm) + " "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("q-separator"),
              _c(
                "q-card-section",
                { staticClass: "uptype-content-material p-2" },
                [
                  _c("q-tree", {
                    ref: "upTypeTree",
                    attrs: {
                      nodes: _vm.upTypeTree,
                      "node-key": "materialTypeCd",
                      "label-key": "materialTypeName",
                      "children-key": "children",
                      "no-nodes-label": "상위유형이 없습니다.",
                      "no-results-label": "",
                      "default-expand-all": false,
                      "selected-color": "primary",
                      selected: _vm.selectedUpType,
                    },
                    on: {
                      "update:selected": function ($event) {
                        _vm.selectedUpType = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }